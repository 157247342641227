import { Injectable, LOCALE_ID, OnDestroy, inject } from '@angular/core';
import { DEFAULT_LANGUAGE } from '@lib/constants';
import { AppLanguage } from '@lib/types';
import { storage } from '@lib/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageService implements OnDestroy {
    constructor(private _translateService: TranslateService) {
        this._translateService.setDefaultLang(this._storedLanguage || DEFAULT_LANGUAGE);
    }

    currentLanguage$ = new BehaviorSubject<AppLanguage>(this._storedLanguage);

    private readonly _locale = inject(LOCALE_ID);

    private readonly _destroy$ = new Subject();

    public get currentLanguage(): AppLanguage {
        return this.currentLanguage$.getValue();
    }

    public get systemLanguage(): AppLanguage {
        return this._locale.split('-').shift() === 'id' ? 'id' : 'en';
    }

    private get _storedLanguage(): AppLanguage {
        return storage.getItem('appLanguage') || DEFAULT_LANGUAGE;
    }

    private set _storedLanguage(language: AppLanguage) {
        storage.setItem('appLanguage', language);
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }

    init(): void {
        this.setLanguage(this._storedLanguage || DEFAULT_LANGUAGE);
    }

    /**
     * Manually changes language in LocalStorage & HTML body
     *
     * @param language new language
     */
    setLanguage(language: AppLanguage): void {
        if (language === 'system') {
            language = this.systemLanguage;
        }

        this._storedLanguage = language;
        this.currentLanguage$.next(language);
        this._translateService.use(language);
    }
}
